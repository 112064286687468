import React, { memo, useMemo } from 'react'
import PageTitle from '@/components/PageTitle'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

function Map(): JSX.Element {
  return (
    <div className={styles.Map}>
      <div className={styles.Image}></div>
      <div className={styles.Cover}></div>
    </div>
  )
}

function Contact(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Map></Map>
      <div className={styles.ContactContainer}>
        <div className={styles.PCInfo}>
          <div style={{ width: '100%' }} id="contact">
            <PageTitle title={t('contact')} subTitle="Contact Us" />
          </div>
          <div className={styles.CardWrap}>
            {/* {infoList.map((info) => (
              <div className={styles.Item} key={info.name}>
                <div className={styles.ItemTitle}>
                  <img src="https://tc-cdn.123u.com/official-website/contact/logo.png" />
                  <div className={styles.VerticalLine}></div>
                  {info.title}
                </div>
                <div className={styles.ItemContentWrap}>
                  <div className={styles.ItemContent}>
                    <div>{info.name}</div>
                    {info.wechat && (
                      <div>
                        {t('wechat')}：{info.wechat}
                      </div>
                    )}
                    <div>
                      {t('qq')}：{info.qq}
                    </div>
                    <div>
                      {}：{info.email}
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
            <div className={styles.Info}>
              <div className={styles.Row}>
                <span className={styles.Title}>{t('address')}：</span>
                <span className={styles.Content}>{t('address info')}</span>
              </div>
              <div className={styles.Row}>
                <span className={styles.Title}>{t('phone')}：</span>
                <span className={styles.Content}>021-55698900</span>
              </div>
              <div className={styles.Row}>
                <span className={styles.Title}>{t('email')}：</span>
                <span className={styles.Content}>
                  nonowang@huanle.com（{t('business cooperation')}）
                </span>
              </div>
              <div className={styles.Row}>
                <span className={styles.Title}></span>
                <span className={styles.Content}>
                  qiqili@huanle.com ({t('human resource')})
                </span>
              </div>
            </div>
            <div className={styles.QrCode}>
              <div className={styles.Row}>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/official-site-v2.jpg"></img>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/video-v2.jpg"></img>
              </div>
              <div className={styles.Row}>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/wechat-public-v2.jpg"></img>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/joy-club-v2.jpg"></img>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.MobileInfo}>
          <div style={{ width: '100%', marginTop: 60 }} id="contact">
            <PageTitle title={t('contact')} subTitle="Contact Us" />
          </div>
          <div className={styles.Contact}>
            {/* {infoList.map((info) => (
              <div className={styles.Info} key={info.name}>
                <div className={styles.Left}>{info.briefTitle}</div>
                <div className={styles.Right}>
                  <div>{info.name}</div>
                  <div>
                    {t('wechat')}：{info.wechat}
                  </div>
                  <div>
                    {t('qq')}：{info.qq}
                  </div>
                  <div>
                    {t('email')}：{info.email}
                  </div>
                </div>
              </div>
            ))} */}
            <div className={styles.Info}>
              <div className={styles.Row}>
                <span className={styles.Title}>{t('address')}：</span>
                <span className={styles.Content}>{t('address info')}</span>
              </div>
              <div className={styles.Row}>
                <span className={styles.Title}>{t('phone')}：</span>
                <span className={styles.Content}>021-55698900</span>
              </div>
              <div className={styles.Row}>
                <span className={styles.Title}>{t('email')}：</span>
                <span className={styles.Content}>
                  nonowang@huanle.com（{t('business cooperation')}）
                </span>
              </div>
              <div className={styles.Row}>
                <span className={styles.Title}></span>
                <span className={styles.Content}>
                  qiqili@huanle.com ({t('human resource')})
                </span>
              </div>
            </div>
            <div className={styles.QrCode}>
              <div className={styles.Row}>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/official-site-v2.jpg"></img>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/video-v2.jpg"></img>
              </div>
              <div className={styles.Row}>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/wechat-public-v2.jpg"></img>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/joy-club-v2.jpg"></img>
              </div>
              <div className={styles.QrCodeTip}>{t('qrcode tips')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(Contact)
